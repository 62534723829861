import axios from 'axios'
import { getToken } from '_utils'
import router from '../router'
import store from '../store'
// 对于axios 统一定义默认配置 baseURL 和 timeout
import { message } from 'element-ui'
const request = axios.create({
  // baseURL在开发时是开发的源，在上线后是上线的源
  baseURL: process.env.VUE_APP_BASEURL == 1 ? window.location.protocol +'//45.61.150.102:82/api' : window.location.protocol+'//' + window.location.host + '/api',
  // baseURL: 'http://45.61.150.102:82/api',
  // baseURL: 'http://' + window.location.host + '/api',
  timeout: 60000,
  headers: { Authorization: getToken() }

})
// let loading = null
request.interceptors.request.use(
  function (config) {
    if (config.url.slice(0, 25) === '/domains/certDownload?id=') {
      config.responseType = 'blob'
    }
    store.commit('user/SET_LOADING', true)
    // 添加 onDownloadProgress 配置来监听下载进度
    config.onDownloadProgress = function (progressEvent) {
      // 计算并更新进度条的百分比
      const percentage = Math.round(
        (progressEvent.loaded / progressEvent.bytes) * 100
      )
      if (percentage <= 100) {
        store.commit('user/SET_PERCENTAGE', percentage)
      }
      // 这里可以根据进度更新UI或执行其他操作
    }
    // 在请求拦截中携带token
    if (getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  function (error) {
    store.commit('user/SET_LOADING', false)
    return Promise.reject(error)
  }
)

// Add a response interceptor
request.interceptors.response.use(
  function (res) {
    const errCode = [401, 403]
    if (errCode.includes(res.data.code)) {
      message({
        type: 'error',
        message: '登陆状态过期！',
        duration: 1500,
        customClass: 'messageIndex',
        onClose: () => {
          store.commit('user/LOG_OUT')
          store.commit('menu/RESET_STATE')
          // 跳转登陆页面
          router.push('/login')
          // this.$router.replace('/login')
        }
      })
    } else if (res.data.code !== 200) {

      if (res.config.url.slice(0, 25) != '/domains/certDownload?id=' && res.config.url.slice(0, 21) != '/file/downloads?path=') {
        if(res.config.url !='/getIps'){
          message({
          message: res.data.data,
          customClass: 'messageIndex',
          type: 'error' // 失败后的弹窗样式
        })
        }
      }
      // 请求失败后
      // 组件库中的方法弹出消息提示，新增商品失败
    }
    store.commit('user/SET_LOADING', false)
    return res
  },
  function (error) {
    store.commit('user/SET_LOADING', false)
    return Promise.reject(error)
  }
)

export default request
